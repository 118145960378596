(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("XX"), require("THREE"));
	else if(typeof define === 'function' && define.amd)
		define(["XX", "THREE"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("XX"), require("THREE")) : factory(root["XX"], root["THREE"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(this, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__5__) {
return 