
import * as THREE from 'three';
import { cloud, three, data ,compositions} from 'xx-packages';


// MODEL LOADERS
// import OBJLoader from "./loaders/OBJLoader.js"
// import { GLTFLoader } from "./loaders/GLTFLoader.js"
// import DracoLoader  from './loaders/DRACOLoader.js'
// import { FBXLoader } from './loaders/FBXLoader';

import device from "./Device"


import './index.css';



/* globals preloader */


const { cloudClient, typeManager } = cloud;
const { AppSingle, modelLoader } = three;
const { queryStringToObject } = data.conversion;


const preloader = window.preloader

// REGISTER LOADERS 
// OBJLoader(THREE);


// THREE.GLTFLoader = GLTFLoader

// modelLoader.registerLoader({
// 	loader: THREE.OBJLoader,
// 	type: "OBJ"
// })
// modelLoader.registerLoader({
// 	loader: THREE.GLTFLoader,
// 	type: "GLTF"
// })
// modelLoader.registerLoader({
// 	loader: FBXLoader,
// 	type: "FBX"
// })


// const dracoLoader = new DracoLoader()
// dracoLoader.setDecoderPath( process.env.NODE_ENV === 'development'? process.env.PUBLIC_URL + '/draco/' : process.env.REACT_APP_DRACO_URL )

// modelLoader.registerLoader({
// 	loader: dracoLoader,
// 	type: "DRACO"
// })


const params = queryStringToObject(window.location.search);


//  Temp UI setup 
// toggle remix class on body to show / hide UI 
document.getElementById('UIOpen').addEventListener('click', _=>{
	document.body.classList.toggle('remix', true )
})
document.getElementById('UIClose').addEventListener('click', _=>{
	document.body.classList.toggle('remix', false )
})

/* DEFAULT CONSTANTS - OBSOLATE ******************************************/

const inputManConf = {
	mkb: {
		enabled: true,
		channels: {
			ui: {
				enabled: false,
				count: 2,
			},
		},
	},
	touch: {
		enabled: false,
	},
	gamepad: {
		enabled: false,
	},
	midi: {
		enabled: !!params.forwardMIDI,
	},
	network: {
		enabled: false,
		p2pBrokerUrl: 'wss://vjycloud.herokuapp.com/p2p/',
		peerId: params.peerId,
		connection: {
			peerId: params.remotePeerId,
			retryIntervalSeconds: 10,
			maxRetries: 5,
			forwardMIDI: params.forwardMIDI,
		},
	},
};


const appDecl = {
	settings: {
		cloud: {
			//includeMeta: false
			loadThumbnails: false 
		},

		inputMan: inputManConf,

		audioMan: {
			musicMeta: {}
		},
	},

	ui: {
		main: null,
		sys: {
			enabled: true,
			visible: false
		}
	},

	project: {
		doAddHTML: true,
		doAddCSS: true,
		doScroll: false,
		allowSceneHTML: true,
		override: {},
		id: "631f0ac55d66d6c78cfdff78",
		
	},
	dominantContext: 'composition'
};

const uiExp = {
	t: 'UIExp',
	d: {
		infoBox: {
			enabled: true
		},
		paramStack: {
			enabled: true,
			elems: [
				{ t: 'effect' },
				{ t: 'color' }
			]
		}
	}
};

if ( window?._params?.compId ){
	params.compId = window._params.compId
} 
if ( window?._params?.project ){
	params.project = window._params.project
}
if ( !window?._params?.compId && !window?._params?.project  ){
	// params.project = 'HDM Holding Page Alpha'
}
if ( !document.getElementById('canvas') ){
	const canvas = document.createElement('canvas')
	canvas.id = 'canvas'
	document.body.insertBefore( canvas, document.querySelector('#content') )
}
(async function () {
	// const res = await fetch(process.env.PUBLIC_URL + '/config.json');
	// const config = await res.json();

	cloudClient.init({
		// apiBaseUrl: config.cloud.apiBaseUrl,
		storage: window.localStorage,
		settings: appDecl.settings.cloud,
		cloudBaseUrl: './',
	});

	const user = await cloudClient.getCurrentUser();

	window.tm = typeManager

	
		appDecl.project.allowSceneHTML = false;
		appDecl.project.n = 'HDM Holding Page Alpha';
	

	if (!device.hasWebGL) {
		return preloader.showUnsupportedMessage()
	}


	const app = new AppSingle(appDecl);
	app.preloader = preloader
	app.device = device
	
	// monitor.setApp( app )







	
	  app.enableScreenshot = true;
	  app.scene.enableScreenshot = true 
	  window.app = app 
	app.start();
	
	
	





	// monitor.initUI()
})();


